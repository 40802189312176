import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from '@paypalcorp/pp-react';
import LanguageSelector from '../languageSelectorMenu';
import {
  Help,
  Security,
  Feedback,
  ReportInappropriateContent,
  Imprint,
  CopyRight,
  Privacy,
  Policy,
  Legal,
  CombinedFinancialServicesText,
} from './footerLinks';
import templateData from '../../utility/getTemplateData';
import AppDispatcher from '../../dispatcher/AppDispatcher';
import AppStore from '../../stores/AppStore';
import {
  isNotFullScreen,
  isInContext,
  isMobileOrTablet,
} from '../../utility/inContextDonation';
import { isExternalFlow } from '../../utility/productIntegrationUtils';
import { trackErrorIM, trackLinkClick } from '../../fpti/fpti';
import { LINK_NAME } from '../../fpti/fptiConstants';
import classNames from 'classnames';
import withTheme from '../withTheme';

function Footer() {
  const [isLanguageSelectorEnabled, setIsLanguageSelectorEnabled] =
    useState(false);

  if (isExternalFlow() && isInContext() && isNotFullScreen()) {
    return null;
  }

  useEffect(() => {
    setIsLanguageSelectorEnabled(AppStore.getLanguageSelectorState());

    const dispatcherMessages = AppDispatcher.register((payload) => {
      if (payload.action?.actionType === 'SHOW_LANGUAGE_SELECTOR') {
        const { item } = payload.action;
        setIsLanguageSelectorEnabled(item);
      }
    });

    return () => {
      AppDispatcher.unregister(dispatcherMessages);
    };
  }, []);

  const handleSurvey = () => {
    if (window.latmconf && window.latmconf.trackSprigEvent) {
      window.latmconf.trackSprigEvent(
        'donatenodeweb-consumermakedonation-feedbacklink-nov2023-web'
      );
    } else {
      trackErrorIM({
        errorCode: 400,
        errorMessage:
          'window.latmconf or  window.latmconf.trackSprigEvent is undefined, Sprig not loaded',
        fieldName:
          'donatenodeweb-consumermakedonation-feedbacklink-nov2023-web',
      });
    }
  };

  const trackReportLink = () => {
    trackLinkClick(LINK_NAME.REPORT_LINK);
  };

  const footerClass = () => {
    return classNames({
      footer_in_context: isInContext() && isNotFullScreen,
      mobile_footer: isMobileOrTablet(),
    });
  };

  const footerContainerClass = () => {
    return classNames({
      footer_container: true,
      mobile_footer_container: isMobileOrTablet(),
    });
  };

  return (
    <footer className={footerClass()}>
      <Container className={footerContainerClass()} fluid={true}>
        <Row>
          <Col xs={9} md={9} className="footer_d_one">
            <ul>
              <Help />
              <Security />
              <ReportInappropriateContent onClick={trackReportLink} />
              <Feedback handleSurvey={handleSurvey} />
              {/* Austria, Switzerland, Germany Imprint Link */}
              {['AT', 'CH', 'DE'].includes(templateData.country) && <Imprint />}
            </ul>
          </Col>

          {isLanguageSelectorEnabled && (
            <Col xs={3} md={3}>
              <LanguageSelector />
            </Col>
          )}
        </Row>
        <Row className="footer_d_two">
          <Col>
            <ul>
              <div className="copy_right-holder">
                <CopyRight />
              </div>
              <div className="footer_d_two_links">
                <Privacy />
                <Legal />
                <Policy />
              </div>
            </ul>
          </Col>
        </Row>

        {/* Australia Combined Financial Services Guide and Product Disclosure Statement */}
        {templateData.country === 'AU' && <CombinedFinancialServicesText />}
      </Container>
    </footer>
  );
}

export default withTheme(Footer);
