import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Link, Button } from '@paypalcorp/pp-react';
import { Message } from '@paypalcorp/worldready-react';
import templateData from '../../utility/getTemplateData';
import MessageWithHTML from '../MessageWithHtml';

export const Help = () => (
  <li>
    <Link href="/selfhelp/home" size="sm" secondary target="_blank">
      <Message id="common.footerDonate.wr.link.help" />
    </Link>
  </li>
);

export const Security = () => (
  <li>
    <Link
      href="/webapps/mpp/paypal-safety-and-security"
      size="sm"
      secondary
      target="_blank"
    >
      <Message id="common.footerDonate.wr.link.security" />
    </Link>
  </li>
);
export const ReportInappropriateContent = () => (
  <li>
    <Link
      href={`/${templateData.countryForReportLink}/smarthelp/contact-us?email=paypalme`}
      size="sm"
      secondary
      target="_blank"
    >
      <Message id="common.footerDonate.wr.link.report" />
    </Link>
  </li>
);

export const Feedback = (props) => (
  <li className="feedback">
    <Button size="sm" onClick={props.handleSurvey}>
      <Message id="common.footerDonate.wr.link.feedback" />
    </Button>
  </li>
);

export const Imprint = () => (
  <li>
    <Link
      className="ppvx_link"
      href={`/${templateData.country}/webapps/mpp/imprint`}
      size="sm"
      secondary
      target="_blank"
    >
      <Message id="common.footerDonateImprint.wr.link.imprint" />
    </Link>
  </li>
);

export const CopyRight = () => (
  <li className="copy_holder">
    <p className="all_rights">
      <MessageWithHTML
        id="common.footerDonate.footer.copyright"
        currentYear={new Date().getFullYear()}
      />
    </p>
  </li>
);

export const Privacy = () => (
  <li>
    <p className="d_two_link privacy_holder">
      <Link
        href={`/${templateData.country}/webapps/mpp/ua/privacy-full`}
        size="sm"
        secondary
        target="_blank"
      >
        <Message id="common.footerDonate.wr.link.privacy" />
      </Link>
    </p>
  </li>
);

export const Legal = () => (
  <li>
    <p className="d_two_link">
      <Link
        href={`/${templateData.country}/webapps/mpp/ua/legalhub-full`}
        size="sm"
        secondary
        target="_blank"
      >
        <Message id="common.footerDonate.wr.link.legal" />
      </Link>
    </p>
  </li>
);

export const Policy = () => (
  <li>
    <p className="d_two_link">
      <Link
        href={`/${templateData.country}/webapps/mpp/ua/upcoming-policies-full`}
        size="sm"
        secondary
        target="_blank"
      >
        <Message id="common.footerDonate.wr.link.policies" />
      </Link>
    </p>
  </li>
);

export const CombinedFinancialServicesText = () => (
  <Row>
    <Col>
      <div className="conbiner_fin_c">
        <Message
          id="common.footerDonate.wr.link.auCombinedFinancialServicesText"
          link={(innerMessage) => (
            <Link
              href={`/${templateData.country}/webapps/mpp/ua/upcoming-policies-full`}
              size="sm"
              secondary
              target="_blank"
            >
              {innerMessage}
            </Link>
          )}
        />
      </div>
    </Col>
  </Row>
);

Feedback.propTypes = {
  handleSurvey: PropTypes.func,
};
