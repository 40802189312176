import { isExternalFlow } from './productIntegrationUtils';
import templateData from './getTemplateData';
import { isInContext } from './inContextDonation';

// eslint-disable-next-line max-params
function getSocialCampaign(
  messages,
  orgName,
  hostedButtonId,
  campaignId,
  socialTitle,
  email,
  isPostDonationModule,
  shareableLink
) {
  const {
    emailSubject = '',
    emailBody = '',
    mobileText = '',
    facebookMessage = '',
    twitterMessage = '',
    whatsappMessage = '',
    copyLinkHeader = '',
    copyLinkSubheaderBefore = '',
    copyLinkSubheader = '',
    copyText = '',
    copiedText = '',
  } = messages || {};
  const domain = templateData.paypalHostName;

  let shareableUrl = shareableLink;
  if (hostedButtonId) {
    shareableUrl = `${domain}/donate/?hosted_button_id=${hostedButtonId}&source=url`;
  } else if (campaignId) {
    shareableUrl = `${domain}/donate/?campaign_id=${campaignId}&source=url`;
  } else if (email) {
    shareableUrl = `${domain}/donate/?business=${email}&source=url`;
  }

  return {
    email: true,
    emailSubject: encodeURIComponent(
      emailSubject.replace('{donationName}', orgName)
    ),
    emailBody: encodeURIComponent(
      emailBody
        .replace(new RegExp('<br>', 'g'), '\r\n')
        .replace('{donationName}', orgName)
        .replace('{url}', shareableUrl.replace('@', '%40'))
    ),
    mobile: true,
    mobileTitle: encodeURIComponent(
      emailSubject.replace('{donationName}', orgName)
    ),
    mobileText: mobileText.replace('{donationName}', orgName),
    facebook: true,
    facebookMessage: facebookMessage
      .replace('{donationName}', orgName)
      .replace('{url}', shareableUrl),
    facebookMessenger: true,
    whatsapp: true,
    whatsappMessage: encodeURIComponent(
      whatsappMessage
        .replace(new RegExp('<br>', 'g'), '\r\n')
        .replace('{donationName}', orgName)
    ),
    twitter: true,
    twitterMessage: twitterMessage.replace('{donationName}', orgName),
    copyLink:
      isInContext() && isExternalFlow() && !isPostDonationModule ? false : true,
    copyLinkHeader,
    copyLinkSubheaderBefore,
    copyLinkSubheader,
    copyLinkCopy: copyText,
    copyLinkCopied: copiedText,
    title: socialTitle,
    sheet: messages.sheet,
  };
}

export default getSocialCampaign;
